import { render, staticRenderFns } from "./Praia-Da-Vitória.vue?vue&type=template&id=18542afe&scoped=true&"
import script from "./Praia-Da-Vitória.vue?vue&type=script&lang=js&"
export * from "./Praia-Da-Vitória.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18542afe",
  null
  
)

export default component.exports